import { ref, watch } from "@vue/composition-api";

// Notification
import walletRepository from "@/abstraction/repository/walletRepository";

const repository = new walletRepository();
export default function useWallet() {
    const refplaylistListTable = ref(null);
    const userTransaction = ref([]);
    const adminLogs = ref([]);
    const totalPaymentList = ref([]);
    const totalTransactionList = ref([]);
    const perPage = ref(10);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const currentPage = ref(1);
    const searchQuery = ref("");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const pagination = ref({});
    const totalPaymentPagination = ref({});
    const userId = ref();
    const refetchData = () => {
        refplaylistListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    // Table Handlers
    const tableColumns = [
        { key: "transaction_status", sortable: true },
        { key: "amount", sortable: true },
        { key: "transaction_time", sortable: true },
    ];
    const adminLogTableColumns = [
        { key: "admin", sortable: true },
        { key: "log_name", sortable: true },
        { key: "log_type", sortable: true },
        { key: "log_time", sortable: true },
        { key: "user", sortable: true },
        { key: "detail", sortable: true },
    ];
    const totalPaymentTableColumns = [
        { key: "causer", sortable: true },
        { key: "amount", sortable: true },
        { key: "payment_method", sortable: true },
        { key: "payment_status", sortable: true },
        { key: "related_user", sortable: true },
        { key: "detail", sortable: true },
    ];
    const totalTransactionTableColumns = [
        { key: "causer", sortable: true },
        { key: "amount", sortable: true },
        { key: "wallet_balance_before", sortable: true },
        { key: "wallet_balance_after", sortable: true },
        { key: "related_user", sortable: true },
        { key: "detail", sortable: true },
    ];

    const fetchUserWallet = async (data) => {
        try {
            return await repository.index(data);
        } catch (e) {
            return false;
        }
    };
    const fetchAllCurrencies = async (data) => {
        try {
            return await repository.allCurrencies(data);
        } catch (e) {
            return false;
        }
    };
    const showCurrency = async (data) => {
        try {
            return await repository.showCurrency(data);
        } catch (e) {
            return false;
        }
    };
    const giveBonusToUser = async (data) => {
        try {
            return await repository.giveBonusToUser(data);
        } catch (e) {
            return false;
        }
    };
    const editCurrencyTypes = async (data) => {
        try {
            return await repository.editCurrencyTypes(data);
        } catch (e) {
            return false;
        }
    };
    const addCurrencyType = async (data) => {
        try {
            return await repository.addCurrencyType(data);
        } catch (e) {
            return false;
        }
    };
    const fetchTotalsData = async (data) => {
        try {
            return await repository.fetchTotalsData(data);
        } catch (e) {
            return false;
        }
    };
    const fetchUserTransactionList = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}, data = userId.value) => {
        const userTransactionList = await repository.userTransactionList({ filters, pagination: paginate }, data);
        userTransaction.value = userTransactionList.data;
        pagination.value = userTransactionList.pagination;
        return userTransactionList;
    };
    const fetchAdminLogList = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const adminLogList = await repository.adminLogList({ filters, pagination: paginate });
        adminLogs.value = adminLogList.data;
        pagination.value = adminLogList.pagination;
        return adminLogs;
    };
    const fetchTotalPaymentList = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const response = await repository.fetchTotalPaymentList({ filters, pagination: paginate });
        totalPaymentList.value = response.data;
        pagination.value = response.pagination;
        return totalPaymentList;
    };
    const fetchTotalTransactionList = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const response = await repository.fetchTotalTransactionList({ filters, pagination: paginate });
        totalTransactionList.value = response.data;
        pagination.value = response.pagination;
        return totalTransactionList;
    };
    const fetchAdminLogDetails = async (id) => {
        try {
            return await repository.logDetails(id);
        } catch (e) {
            return false;
        }
    };
    const fetchTotalPaymentsDetails = async (id) => {
        try {
            return await repository.totalPayments(id);
        } catch (e) {
            return false;
        }
    };
    const fetchTotalTransactionDetails = async (id) => {
        try {
            return await repository.totalTransactions(id);
        } catch (e) {
            return false;
        }
    };
    const sendMultipleBonuses = async (data) => {
        try {
            return await repository.sendMultipleBonuses(data);
        } catch (e) {
            return false;
        }
    };
    const getReferalCode = async (id) => {
        try {
            return await repository.getReferalCode();
        } catch (e) {
            return false;
        }
    };
    const setReferalCode = async (data) => {
        try {
            return await repository.setReferalCode(data);
        } catch (e) {
            return false;
        }
    };
    // const changePagePlaylist = async (page) => {
    //     const response = await fetchUserTransactionList({ pagination: page });
    //     return response;
    // };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        userTransaction,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        fetchUserTransactionList,
        fetchUserWallet,
        userId,
        fetchAllCurrencies,
        giveBonusToUser,
        addCurrencyType,
        showCurrency,
        editCurrencyTypes,
        fetchAdminLogList,
        adminLogTableColumns,
        adminLogs,
        fetchAdminLogDetails,
        fetchTotalsData,
        fetchTotalPaymentList,
        totalPaymentList,
        totalPaymentPagination,
        totalPaymentTableColumns,
        fetchTotalPaymentsDetails,
        totalTransactionList,
        fetchTotalTransactionList,
        totalTransactionTableColumns,
        fetchTotalTransactionDetails,
        sendMultipleBonuses,
        getReferalCode,
        setReferalCode,
    };
}
