<template>
    <div class="container">
        <b-card>
            <h4 class="font-weight-bolder my-2">Currency Types</h4>
            <b-row v-if="allCurrencies">
                <b-list-group class="col-12">
                    <b-list-group-item v-for="(item, index) in allCurrencies.data" :key="item.id">
                        <b-row>
                            <b-col cols="3">
                                <h5 class="text-dark">Currency type</h5>

                                <b-badge variant="light-primary">
                                    {{ item.currency_type }}
                                </b-badge>
                            </b-col>
                            <b-col cols="3">
                                <h5>Edited by</h5>
                                <b-avatar badge-variant="success" :src="item.edited_by.thumbnail" />
                                <small class="text-secondary">
                                    {{ item.edited_by.user_name }}
                                </small>
                            </b-col>
                            <b-col cols="3">
                                <h5>Exchange rate</h5>
                                1 {{ item.currency_type }} = <b-badge variant="info" class=""> {{ item.exchange_rate }} </b-badge> <b-avatar class="" src="./images/COIN.png" />
                            </b-col>
                            <b-col cols="3">
                                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" @click="editCurrencyHandler(item.id, index)"> Edit </b-button>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-center mt-2">
                            <b-col cols="6">
                                <transition name="slide-fade">
                                    <b-card v-if="editCurrencyCard[index].allow" class="bg-muted edit-card">
                                        <b-row class="justify-content-end">
                                            <b-button @click="editCurrencyCard[index].allow = false" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle">
                                                <feather-icon icon="XIcon" />
                                            </b-button>
                                        </b-row>
                                        <b-row>
                                            <label for="edit-currency">Currency</label>
                                            <b-form-input id="edit-currency" v-model="currencyType" size="sm" />
                                        </b-row>
                                        <b-row>
                                            <label for="edit-exchange">Exchange rate</label>
                                            <b-form-input id="edit-exchange" v-model="exchangeRate" size="sm" />
                                        </b-row>
                                        <b-row class="justify-content-center mt-1">
                                            <b-button v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="flat-info" @click="editTypes(item.id)"> Edit </b-button>
                                        </b-row>
                                    </b-card>
                                </transition>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-row>
            <b-row class="justify-content-center">
                <div class="my-1">
                    <!-- Our triggering (target) element -->
                    <b-button id="popover-reactive-1" class="mr-3" ref="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">Add new</b-button>
                </div>

                <!-- Output from the popover interaction -->
                <b-card v-if="input1Return && input2Return" title="Returned values:" class="shadow-none border mb-0">
                    <p class="card-text" style="max-width: 20rem">
                        Name: <strong>{{ input1Return }}</strong
                        ><br />
                        Color: <strong>{{ input2Return }}</strong>
                    </p>
                </b-card>

                <!-- Our popover title and content render container -->
                <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
                <!-- We specify the same container as the trigger button, so that popover is close to button -->
                <b-popover ref="popover" target="popover-reactive-1" triggers="click" :show.sync="popoverShow" placement="auto" container="my-container" @show="onShow" @shown="onShown" @hidden="onHidden">
                    <template v-slot:title>
                        <div class="d-flex justify-content-between align-items-center">
                            <span>New currency</span>
                        </div>
                    </template>

                    <div>
                        <b-form-group label="Currency type" label-for="popover-input-1" label-cols="4" class="mb-1" description="Enter currency type" invalid-feedback="This field is required">
                            <b-form-input id="popover-input-1" ref="input1" v-model="currencyType" size="sm" />
                        </b-form-group>

                        <b-form-group label="Exchange rate" label-for="popover-input-2" label-cols="4" class="mb-1" description="Enter exchange rate" invalid-feedback="This field is required">
                            <b-form-input id="popover-input-2" ref="input1" v-model="exchangeRate" size="sm" />
                        </b-form-group>

                        <b-alert show class="small mb-1" v-if="currencyType !== '' && exchangeRate !== ''">
                            <div class="alert-body">
                                <strong class="mb-1">Current Values:</strong><br />
                                1 <strong>{{ currencyType }}</strong> = <strong>{{ exchangeRate }}</strong> <b-avatar class="ml-50" src="./images/COIN.png" />
                            </div>
                        </b-alert>

                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="danger" class="mr-1" @click="onClose"> Cancel </b-button>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addExchangeRate"> Ok </b-button>
                    </div>
                </b-popover>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BPopover, BBadge, BListGroup, BListGroupItem, BTable, BAvatar, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BOverlay, BPagination } from "bootstrap-vue";
import { ref, onBeforeUnmount, onMounted, watchEffect } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import UserRepository from "@/abstraction/repository/userRepository";
import useWalletList from "./useWalletList";
const userRepository = new UserRepository();

export default {
    components: {
        BPopover,
        BBadge,
        BListGroup,
        BListGroupItem,
        BPagination,
        BTable,
        BAvatar,
        BOverlay,
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const { fetchAllCurrencies, addCurrencyType, showCurrency, editCurrencyTypes } = useWalletList();
        const allCurrencies = ref();
        const currencyType = ref("");
        const exchangeRate = ref("");
        const editCurrencyCard = ref([]);
        fetchAllCurrencies().then((res) => {
            allCurrencies.value = res;
            res.data.map((item) => {
                return editCurrencyCard.value.push({ allow: false });
            });
        });
        const editCurrencyHandler = (id, index) => {
            editCurrencyCard.value.map((item, index) => {
                return (editCurrencyCard.value[index].allow = false);
            });
            editCurrencyCard.value[index].allow = true;
            showCurrency(id).then((res) => {
                exchangeRate.value = res.data.data.exchange_rate;
                currencyType.value = res.data.data.currency_type;
            });
        };
        const editTypes = (id) => {
            let data = {
                currency_type: currencyType.value,
                exchange_rate: exchangeRate.value,
                id: id,
            };
            editCurrencyTypes(data).then(() => {
                fetchAllCurrencies().then((res) => {
                    allCurrencies.value = res;
                    res.data.map((item) => {
                        return editCurrencyCard.value.push({ allow: false });
                    });
                });
            });
        };
        const addExchangeRate = () => {
            let data = {
                currency_type: currencyType.value,
                exchange_rate: exchangeRate.value,
            };
            addCurrencyType(data).then(() => {
                fetchAllCurrencies().then((res) => {
                    allCurrencies.value = res;
                    res.data.map((item) => {
                        return editCurrencyCard.value.push({ allow: false });
                    });
                });
            });
        };
        return {
            allCurrencies,
            editCurrencyHandler,
            editCurrencyCard,
            currencyType,
            exchangeRate,
            addExchangeRate,
            editTypes,
        };
    },
    data: () => ({
        input1: "",
        input1state: null,
        input2: "",
        input2state: null,
        options: [{ text: "- Choose 1 -", value: "" }, "Red", "Green", "Blue"],
        input1Return: "",
        input2Return: "",
        popoverShow: false,
    }),
    methods: {
        showPopover() {
            this.$root.$emit("bv::show::popover", "popover-button-1");
        },
        onClose() {
            this.popoverShow = false;
        },
        onOk() {
            if (!this.input1) {
                this.input1state = false;
            }
            if (!this.input2) {
                this.input2state = false;
            }
            if (this.input1 && this.input2) {
                this.onClose();
                // Return our popover form results
                this.input1Return = this.input1;
                this.input2Return = this.input2;
            }
        },
        onShow() {
            // This is called just before the popover is shown
            // Reset our popover form variables
            console.log("HHH");
            this.exchangeRate = "";
            this.currencyType = "";
            this.input1state = null;
            this.input2state = null;
            this.input1Return = "";
            this.input2Return = "";
        },
        onShown() {
            // Called just after the popover has been shown
            // Transfer focus to the first input
            this.focusRef(this.$refs.input1);
        },
        onHidden() {
            // Called just after the popover has finished hiding
            // Bring focus back to the button
            this.focusRef(this.$refs.button);
        },
        focusRef(ref) {
            // Some references may be a component, functional component, or plain element
            // This handles that check before focusing, assuming a `focus()` method exists
            // We do this in a double `$nextTick()` to ensure components have
            // updated & popover positioned first
            this.$nextTick(() => {
                this.$nextTick(() => {
                    (ref.$el || ref).focus();
                });
            });
        },
    },
};
</script>

<style>
.popoverWidth {
    width: 550px;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.edit-card {
    /* position: absolute;
    top: 400px; */
    /* width: 300px;
    height: 150px; */
}
</style>
